import React, { useEffect, useState, useRef } from "react";
import { graphql } from "gatsby";
import { DateTime } from "luxon";
import emailjs from "@emailjs/browser";

import Layout from "../components/Layout/Layout";
import Head from "../components/Head/Head";
import Hero from "../components/Hero/Hero";

import BookNowStyles from "./book-now.module.scss";

const BookNow = ({ data, location }) => {
  const { pageDetails } = data;

  const formRef = useRef();
  const [formSubmissionStatus, setFormSubmissionStatus] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.GATSBY_EMAILJS_SERVICE_ID,
        process.env.GATSBY_EMAILJS_TEMPLATE_ID,
        formRef.current,
        process.env.GATSBY_EMAILJS_USER_ID
      )
      .then(
        () => {
          setFormSubmissionStatus("success");
        },
        () => {
          setFormSubmissionStatus("fail");
        }
      );
  };

  const [cutOffDate, setCutOffDate] = useState("");
  useEffect(() => {
    const now = DateTime.now();
    const cutOff = now.minus({ years: 18 }).toFormat("y-MM-dd");
    setCutOffDate(cutOff);
  }, []);

  const [birthday, setBirthday] = useState(cutOffDate);
  useEffect(() => {
    if (!birthday) {
      setBirthday(cutOffDate);
    }
  }, [birthday, cutOffDate]);
  const [birthdayError, setBirthdayError] = useState(false);
  const handleBirthdayChange = (event) => {
    setBirthday(event.target.value);
  };

  useEffect(() => {
    if (birthday > cutOffDate) {
      setBirthdayError(true);
    }
    if (birthday < cutOffDate) {
      setBirthdayError(false);
    }
  }, [birthday, cutOffDate]);

  const [preferredBookingDate, setPreferredBookingDate] = useState("");
  const handleBookingDateChange = (event) => {
    setPreferredBookingDate(event.target.id);
  };

  return (
    <Layout>
      <Head
        metaContent={pageDetails.metaContent}
        location={location.pathname}
      />
      <Hero
        heading={pageDetails.pageHeader}
        intro={pageDetails.pageContent.pageContent}
        className={BookNowStyles.hero}
      />
      {!formSubmissionStatus && (
        <section className={BookNowStyles.formSection}>
          <form
            id="booking-form"
            ref={formRef}
            className={BookNowStyles.form}
            onSubmit={handleFormSubmit}
          >
            <fieldset className={BookNowStyles.contactInfo}>
              <legend>Contact Information</legend>
              <label htmlFor="firstName">
                <span className={BookNowStyles.required}>First Name</span>
                <input type="text" id="firstName" name="firstName" required />
              </label>
              <label htmlFor="lastName">
                <span className={BookNowStyles.required}>Last Name</span>
                <input type="text" id="lastName" name="lastName" required />
              </label>
              <label htmlFor="pronouns">
                <span>Pronouns</span>
                <input type="text" id="pronouns" name="pronouns" />
              </label>
              <label htmlFor="phone">
                <span>Phone Number</span>
                <input type="tel" id="phone" name="phone" />
              </label>
              <label htmlFor="email">
                <span className={BookNowStyles.required}>Email</span>
                <input type="email" id="email" name="email" required />
              </label>
              <label htmlFor="birthday">
                <span>Birthday</span>
                <input
                  type="date"
                  id="birthday"
                  name="birthday"
                  max={cutOffDate}
                  value={birthday}
                  onChange={handleBirthdayChange}
                />
                {birthdayError && (
                  <span className={BookNowStyles.birthdayError}>
                    Sorry! You must be 18+ in order to book a tattoo.
                  </span>
                )}
              </label>
            </fieldset>

            <label htmlFor="whatGet" className={BookNowStyles.textQuestion}>
              <span className={BookNowStyles.required}>
                What tattoo do you want?
              </span>
              <span className={BookNowStyles.helperText}>
                Describe your idea as best as you can! If you are unsure of
                anything we can discuss details in a free consultation.
              </span>
              <textarea
                name="whatGet"
                id="whatGet"
                cols="0"
                rows="0"
                required
              ></textarea>
            </label>

            <fieldset>
              <legend className={BookNowStyles.required}>
                When do you want to get it?
              </legend>
              <label htmlFor="specificDate">
                <input
                  type="radio"
                  id="specificDate"
                  name="whenYouWantTattoo"
                  value="I want a specific day."
                  required
                  onChange={handleBookingDateChange}
                />
                <span>I want a specific day.</span>
              </label>
              <label htmlFor="dateRange">
                <input
                  type="radio"
                  id="dateRange"
                  name="whenYouWantTattoo"
                  value="I have a general date range."
                  required
                  onChange={handleBookingDateChange}
                />
                <span>I have a general date range.</span>
              </label>
              <label htmlFor="asap">
                <input
                  type="radio"
                  id="asap"
                  name="whenYouWantTattoo"
                  value="As soon as possible!"
                  required
                  onChange={handleBookingDateChange}
                />
                <span>As soon as possible!</span>
              </label>
              {preferredBookingDate === "asap" && (
                <p className={BookNowStyles.asapMessage}>
                  Great! I'll find the next slot available and let you know when
                  I get back to you.
                </p>
              )}
            </fieldset>

            {preferredBookingDate === "specificDate" && (
              <label
                htmlFor="selectSpecificDate"
                className={`${BookNowStyles.textQuestion} ${BookNowStyles.specificDate}`}
              >
                <span>What day do you want?</span>
                <input
                  type="date"
                  id="selectSpecificDate"
                  name="selectSpecificDate"
                  required
                />
              </label>
            )}

            {preferredBookingDate === "dateRange" && (
              <fieldset className={BookNowStyles.dateRange}>
                <legend>What range works best for you?</legend>
                <label
                  htmlFor="rangeStart"
                  className={BookNowStyles.textQuestion}
                >
                  <span className="sr-only">The first date in your range</span>
                  <span>From</span>
                  <input
                    type="date"
                    id="rangeStart"
                    name="rangeStart"
                    required
                  />
                </label>
                <label
                  htmlFor="rangeEnd"
                  className={BookNowStyles.textQuestion}
                >
                  <span className="sr-only">The last date in your range</span>
                  <span>To</span>
                  <input type="date" id="rangeEnd" name="rangeEnd" required />
                </label>
              </fieldset>
            )}

            <label htmlFor="where" className={BookNowStyles.textQuestion}>
              <span>Where do you want it?</span>
              <input type="text" id="where" name="where" />
            </label>

            <label htmlFor="howBig" className={BookNowStyles.textQuestion}>
              <span>How big do you want it?</span>
              <input type="text" id="howBig" name="howBig" />
            </label>

            <fieldset>
              <legend className={BookNowStyles.required}>
                Colour, black and grey, or linework?
              </legend>
              <label htmlFor="colour">
                <input
                  type="radio"
                  id="colour"
                  name="tattooStyle"
                  value="Colour"
                  required
                />
                <span>Colour</span>
              </label>
              <label htmlFor="blackAndGrey">
                <input
                  type="radio"
                  id="blackAndGrey"
                  name="tattooStyle"
                  value="Black and Grey"
                  required
                />
                <span>Black and Grey</span>
              </label>
              <label htmlFor="linework">
                <input
                  type="radio"
                  id="linework"
                  name="tattooStyle"
                  value="Linework"
                  required
                />
                <span>Linework</span>
              </label>
              <label htmlFor="iDontKnow">
                <input
                  type="radio"
                  id="iDontKnow"
                  name="tattooStyle"
                  value="I'm not sure"
                  required
                />
                <span>I'm not sure</span>
              </label>
            </fieldset>

            <fieldset>
              <legend className={BookNowStyles.required}>
                Is this a cover-up?
              </legend>
              <label htmlFor="yesCoverUp">
                <input
                  type="radio"
                  id="yesCoverUp"
                  name="coverUp"
                  value="Yes"
                  required
                />
                <span>Yes</span>
              </label>
              <label htmlFor="noCoverUp">
                <input
                  type="radio"
                  id="noCoverUp"
                  name="coverUp"
                  value="No"
                  required
                />
                <span>No</span>
              </label>
            </fieldset>

            <fieldset>
              <legend className={BookNowStyles.required}>
                Is this appointment just for you, or for multiple people?
              </legend>
              <label htmlFor="solo">
                <input
                  type="radio"
                  id="solo"
                  name="soloOrMultiplePeople"
                  value="Just for me."
                  required
                />
                <span>Just for me.</span>
              </label>
              <label htmlFor="oneToThreePeople">
                <input
                  type="radio"
                  id="oneToThreePeople"
                  name="soloOrMultiplePeople"
                  value="Me and 1-3 people."
                  required
                />
                <span>Me and 1-3 people.</span>
              </label>
              <label htmlFor="threePlusPeople">
                <input
                  type="radio"
                  id="threePlusPeople"
                  name="soloOrMultiplePeople"
                  value="Me and 3+ people."
                  required
                />
                <span>Me and 3+ people.</span>
              </label>
            </fieldset>

            <label
              htmlFor="anythingElse"
              className={BookNowStyles.textQuestion}
            >
              <span>Is there anything else I should know?</span>
              <span className={BookNowStyles.helperText}>
                Do you have any other questions for me about your appointment?
              </span>
              <textarea
                name="anythingElse"
                id="anythingElse"
                cols="0"
                rows="0"
              ></textarea>
            </label>
            <div className={BookNowStyles.formEnd}>
              <p>
                Once you submit your booking request, I will be in touch with
                you as soon as I can!
              </p>
              <button type="submit" className="button">
                Submit Request
              </button>
            </div>
          </form>
        </section>
      )}
      {formSubmissionStatus === "success" && (
        <section className={BookNowStyles.submitMessage}>
          <h2>Let's make some art</h2>
          <p>
            <span className={BookNowStyles.separateLine}>
              Your appointment has been booked!
            </span>{" "}
            Keep an eye on your email because I should get back to you in the
            next few days. In the meantime, why not go outside and enjoy the
            fresh air?
          </p>
        </section>
      )}
      {formSubmissionStatus === "fail" && (
        <section
          className={`${BookNowStyles.submitMessage} ${BookNowStyles.fail}`}
        >
          <h2>Hmm, that didn't go as planned...</h2>
          <p>
            It seems there was an error with your request. Why not{" "}
            <a href="/book-now/#booking-form">try again</a>? If the problem
            persists,{" "}
            <a href="mailto:emmajames.tattoo@gmail.com">shoot me an email</a>{" "}
            and let me know you're having troubles with the form!
          </p>
        </section>
      )}
    </Layout>
  );
};

export default BookNow;

export const query = graphql`
  query {
    pageDetails: contentfulBookNowPage {
      metaContent {
        title
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
      pageHeader
      pageContent {
        pageContent
      }
    }
  }
`;
